<template>
  <div>
    <b-hero
      v-if="showHero"
      :image-url="hero.image.value"
      :title="hero.title"
      :subheading="hero.subheading"
    />
    <markup-content v-if="topContent" :content="topContent" class="py-12" />
    <div v-if="filterByField" class="flex flex-wrap px-6 mt-4">
      <b-button
        :variant="selectedFilterBy === null ? 'primary' : 'secondary'"
        class="mr-4 mb-4"
        @click="selectedFilterBy = null"
      >
        All
      </b-button>
      <b-button
        v-for="(filter, index) in filters"
        :key="`filter-${index}`"
        :variant="filter === selectedFilterBy ? 'primary' : 'secondary'"
        class="mr-4 mb-4"
        @click="selectedFilterBy = filter"
      >
        {{ filter }}
      </b-button>
    </div>
    <div
      class="grid grid-cols-3 gap-4 px-6 pb-6"
      :class="{ 'py-6': !filterByField }"
    >
      <router-link
        v-for="item in filteredItems"
        :key="`item-${item.id}`"
        :to="{ name: 'preview-detail-view', params: { slug: item.slug } }"
      >
        <b-content-card
          :key="`${item.id}`"
          :item="item"
          :config="listItemConfig"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import BHero from "@/components/BHero";
import BButton from "@/components/BButton";
import BContentCard from "@/components/BContentCard";
import MarkupContent from "@/components/MarkupContent";
import { mapState } from "vuex";

export default {
  name: "BuilderPreview",

  components: {
    BHero,
    BButton,
    BContentCard,
    MarkupContent
  },

  computed: {
    ...mapState("builder", ["filters", "items", "listItemConfig"]),
    filterByField() {
      return this.$store.state.builder.listItemConfig.filterByField;
    },
    selectedFilterBy: {
      get() {
        return this.$store.state.builder.selectedFilter;
      },
      set(val) {
        this.$store.commit("builder/SET_SELECTED_FILTER", val);
      }
    },
    filteredItems() {
      return this.items.filter(item =>
        this.selectedFilterBy === null
          ? true
          : item[this.filterByField.name] === this.selectedFilterBy
      );
    },
    showHero() {
      if (this.hero && this.hero.show) {
        return true;
      }
      return false;
    },
    hero() {
      return this.listItemConfig?.hero;
    },
    topContent() {
      return this.listItemConfig?.topContent;
    }
  }
};
</script>
